.home-faq-single-qa-wrapper {
  background: rgb(249 250 251) !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.5rem;
  padding: 35px 40px;
  border: 1px solid transparent;

  &.active .landing-qa-section-single-qa-answer-wrapper {
    opacity: 1;
  }

  &:hover {
    border-color: gray;
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-bottom: 30px;

    @media (max-width: 599px) {
      margin-bottom: 20px;
    }

    @media (max-width: 499px) {
      margin-bottom: 10px;
    }
  }

  .landing-qa-section-single-qa-visible-wrapper {
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    svg {
      min-width: 26px;
      min-height: 26px;
      path {
        stroke: black;
      }
    }

    .landing-qa-section-single-qa-visible-question {
      font-size: 20px;
      user-select: none;
      color: rgb(2 6 23);
      margin-right: 10px;
      transition: all 420ms cubic-bezier(.165,.84,.44,1);
    }
  }

  .landing-qa-section-single-qa-answer-wrapper {
    will-change: opacity, height;
    overflow: hidden;
    transition: all 160ms ease-in-out;
    color: gray;

    .landing-qa-section-single-qa-answer-content {
      font-size: 18px;
      line-height: 20px;
      padding-top: 20px;
      max-width: 970px;
      color: rgb(2 6 23);

      a {
        color: #ADFF00;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .landing-qa-section-single-qa-visible-wrapper {
      .landing-qa-section-single-qa-visible-question {
        font-size: 17px;
      }
    }

    .landing-qa-section-single-qa-answer-wrapper .landing-qa-section-single-qa-answer-content { font-size: 15px; }
  }

  @media only screen and (max-width: 530px) { padding: 20px; }
}
