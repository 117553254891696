.service-link {
  cursor: pointer !important;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0);
}