.serbia-index .nav-navbar {
  width: 720px;
  height: 58px;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(90deg, #253036,  rgba(76, 175, 80, 0), #66c586,#66c586);
  left: 50%;
  transform: translateX(-50%);
  padding: 1px;
  top: 15px;
}
.serbia-index .nav-navbar .nav-container {
  width: 100%;
  height: 56px;
  background-image: linear-gradient(90deg, rgba(21, 38, 44,.9),rgba(16, 29, 35,.9));
  backdrop-filter: blur(10px);
  border-radius: 30px 30px 30px 30px;
  position: relative;
  padding:0 10px;
}
.serbia-index .navbar-nav{
  height: 56px;
  justify-content: center;
  overflow: hidden;
}
.serbia-index .nav-item {
  position: relative;
  margin:0 12px;
  color: #61DC82;
  text-align: center;
  height: 56px;
  line-height: 56px;
  transition: all 0.3s ease;
}
.serbia-index .nav-item::after{
  transition: all 0.6s ease;
  content: '';
  position: absolute;
  width:60px;
  height: 60px;
  background-image: radial-gradient(circle, rgba(97, 220, 129, 0.5) 0%, transparent 70%);
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
}
.serbia-index .nav-item.active::after,
.serbia-index .nav-item:hover::after {
  bottom: -30px;
}
.download-btn {
  height: 44px;
  padding: 0 20px;
  white-space: nowrap;
  background: #61DC82;
  box-shadow: 0 0 30px rgba(97, 220, 129, 0.8);
  transition: all 0.3s ease;
}
.download-btn:hover {
  transform: translateY(-1px);
}
.logo-container {
  position: relative;
  font-size: 0;
}
.logo-container img {
  width: 46px;
  height: 46px;
}
.serbia-index{
  overflow: hidden;
  background: #141d22;
  color: #fff;
  min-height: 100vh;
}
.block-conainer{
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
}
.index-main{
  position: relative;
}
.index-main::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1514px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../images/serbia/pc-bg.png);
}
.index-banner{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.banner-text-bg{
  position: relative;
  z-index: 3;
  max-width: 1442px;
  animation: float 5s ease-in-out infinite;
}
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.banner-swiper{
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  width: 318px;
  height: 637px;
  transform: translate(-50%,-50%);
  background-color: #000;
  border-radius: 50px;
  background-image: url(../images/serbia/phone.png);
  background-size: 100% 100%;
  padding: 20px;
  background-repeat: no-repeat;
}
.banner-swiper img{
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.block-main{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.index-section{
  padding-top: 200px;
  padding-bottom: 100px;
}



/* footer */
.index-footer{
  background-color: #1a242d;
  padding-bottom: 100px;
  padding-top: 100px;
  margin-top: 100px;
}
.footer-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #3F5057;
  padding-top: 20px;
  margin-top: 15px;
}
.footer-logo{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.footer-logo img{
  width: 35px;
  height: 35px;
}
.footer-logo span{
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #3EA860 31%, #61DC82 77%);
}

.footer-link{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.footer-link a{
  color: #3F5057;
}
.color-green{
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #3EA860 31%, #61DC82 77%);
}
.color-gray{
  color: #3F5057;
}
.footer-right{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.footer-top{
  position: relative;
}

.link-us{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size:42px;
  font-weight: 600;
  line-height: 42px;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(-90deg, #8B8B8B 0%, #FFFFFF 49%);
}
.link-us span{
  width: 36px;
  height: 36px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position:center;
  background-size: contain;
  transition: all 0.3s ease;
  background-image: url(../images/serbia/share.png);
}
.link-us:hover span{
  transform: rotate(45deg);
}
.footer-link a:hover{
  color: #61DC82;
}
.footer-share{
  display: flex;
  align-items: center;
  gap: 20px;
}
.footer-share .share-link img{
  width: 33px;
}


.block-top{
  position: relative;
  border: 1px solid #3F5057;
  background: #121B1F;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #3EA860 31%, #61DC82 77%);
  border-radius: 10px;
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  border-radius: 26px;
  padding: 26px 90px;
}
.block-footer{
  position: relative;
  border: 1px solid #3F5057;
  background: #121B1F;
  border-radius: 26px;
  font-weight: 600;
  line-height: 32px;
  padding: 26px 26px 26px 145px;
  font-size: 26px;
}
.block-footer .block-icon{
  position: absolute;
  top: -15px;
  left: 26px;
  width: 110px;
}
.block-number{
  font-family: Impact;
  font-size: 283px;
  text-align: center;
  color: #B5F1D8;
  font-style: normal;
  text-transform: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(180deg, #3EA860 31%, #141d22 87%);
  line-height: 1;
}
.block-item-title{
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  color: #3EA860;
  text-align: left;
  font-style: normal;
  text-transform: none;
  text-align: center;
}
.block-item{
  margin-top: 16px;
  color: #4B7457;
  text-align: center;
}
.block-item-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.block-item-icon img{
  width: 24px;
}
.block-download{
  text-align: center;
  margin-top: 30px;
}
.block-download .btn-download{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 0 30px;
  background: radial-gradient( 100% 0% at 0% 0%, #3EA860 31%, #61DC82 77%), #B5F1D8;  
  box-shadow: 0 0 30px rgba(97, 220, 129, 0.8);
  transition: all 0.3s ease;
  border-radius: 26px;
  line-height: 32px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.block-photo{
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.block-panel-items{
  position: relative;
  width: 400px;
  padding: 0 10px;
}
.block-panel-items::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: #3F5057;
}

.block-panel-items::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width:2px;
  height: 90%;
  background-color: #3ea860;
  box-shadow: 0 0 10px rgba(97, 220, 129, 0.9);
}
.block-body{
  max-width: 700px;
}

.block-lang{
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid #3F5057;
  box-shadow: inset 0 0 10px #3F5057;
  padding: 5px;
  margin-left: 70px;
}
.block-lang img{
  width: 45px;
  height: 26px;
  border-radius: 40px;
}
.block-lang::before{
  content: '';
  position: absolute;
  top: -6px;
  left: -6px;
  bottom: -6px;
  right: -6px;
  border: 1px solid #3F5057;
  border-radius: 40px;
}
.block-video{
  width: 700px;
  height: 420px;
}
.section-header{
  position: relative;
  text-align: center;
  max-width: 1030px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding-top: 100px;
  padding-bottom: 40px;
}
.section-header::before{
  content: '';
  position: absolute;
  top: 50px;
  left: 32%;
  width: 35px;
  height: 35px;
  background-image: radial-gradient(circle, rgba(97, 220, 129, 0.5) 0%, rgba(97, 220, 129, 0.179) 70%);
  border-radius: 50%;
  box-shadow: 0 0 50px rgba(97, 220, 129, 1);
  border: 0;
}
.section-header .section-title{
  font-size: 36px;
  font-weight: 600;
  color: #3EA860;
  margin-bottom: 20px;
}
.section-header .section-description{
  font-size: 18px;
  color: #3F5057;
  max-width: 430px;
  margin: 0 auto;
}
.section-hor{
  position: absolute;
  height: 2px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-image: linear-gradient(90deg, #141d22 0%, #3f5057 50%, #141d22 100%);
}
.section-ver{
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  background-image: linear-gradient(0deg, #141d22 0%, #3f5057 50%, #141d22 100%);
}

.section-hor.hor-top{
  top: 0;
}
.section-hor.hor-bottom{
  bottom: 0;
}
.section-ver.ver-left{
  left: 20%;
}
.section-ver.ver-right{
  right: 20%;
}

.index-section .video-react .video-react-big-play-button{
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.index-section .video-react .video-react-poster{
  background-size: cover;
}
.gap-6{
  gap: 6px;
}
.reviews-li{
  margin-bottom: 6px;
  padding: 1px;
  border-radius: 24px 24px 24px 24px;
  background-image: linear-gradient(180deg, #253036, rgba(76, 175, 80, 0), #606060, #606060);
}
.reviews-card{
  padding: 40px;
  border-radius: 24px 24px 24px 24px;
  background-image: linear-gradient(180deg, #1b282c, #1d272c, #3d5056);
}
.reviews-body{
  color: #3F5057;
  min-height: 64px;
  line-height: 1.2;
  margin-bottom: 12px;
}
.reviews-footer{
  display: flex;
  align-items: center;
}
.reviews-item{
  color: #fff;
}
.reviews-avatar{
  font-size: 0;
  margin-left: auto;
}
.reviews-avatar img{
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
}
.reviews-footer .line{
  width: 1px;
  height: 10px;
  background-color: #FFFFFF;
  margin: 0 20px;
}
.reviews-card .mdi-star{
  color: #3EA860;
}
.contact-link{
  text-align: center;
}
@media (min-width: 1024px) {
  .contact-link{
    position: absolute;
    top: -40px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }  
}
.menu-button{
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  background-image: linear-gradient(to right,#3ea860,#61dc82);
}
.m-block-video{
  width: 100%;
}
.index-m-video{
  display: none;
}

.menu-collapse{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(30px);
  transform: translateY(-100%);
  z-index: 999;
  color: #fff;
  transition: all 0.4s ease;
}
.menu-collapse.is-open{
  transform: translateY(0);
}
.navbar-menu{
  padding:60px 30px;
  color: #fff;
}
.nav-menu-item{
  margin: 4px 0;
  padding: 10px 0;
  transition: all 0.5s ease;
  opacity: 0;
  transform: translateY(20px);
}
.menu-collapse.is-open .nav-menu-item {
  opacity: 1;
  transform: translateY(0);
}
.nav-menu-item:nth-child(1) {
  transition-delay: 0.3s;
}
.nav-menu-item:nth-child(2) {
  transition-delay: 0.5s;
}
.nav-menu-item:nth-child(3) {
  transition-delay: 0.7s;
}
.nav-menu-item:nth-child(4) {
  transition-delay: 0.9s;
}
.nav-menu-item:nth-child(5) {
  transition-delay: 1.1s;
}
.navbar-menu-lang .nav-menu-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 8px 0;
  transition: all 0.1s ease;
}
.nav-menu-item.active{
  background-color: rgba(199, 213, 238, .35);
}
.navbar-menu-lang .nav-menu-item .uil-check{
  opacity: 0;
}
.navbar-menu-lang .nav-menu-item.active .uil-check{
  opacity: 1;
}





.navbar-menu .nav-link{
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
}
.nav-link img{
  width: 20px;
}
.menu-close{
  position: absolute;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: url(../images/serbia/close-icon.png) no-repeat center;
  background-size: contain;
  top: 25px;
  right: 25px;
}
.menu-back{
  position: absolute;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: url(../images/serbia/back.png) no-repeat center;
  background-size: contain;
  top: 25px;
  right: 25px;

}
.pc-lang{
  position: fixed;
  top: 15px;
  right: 25px;
  z-index: 12;
}
.lang-button{
  position: relative;
  display: flex;
  align-items: center;
  width: 175px;
  height: 52px;
  border-radius: 10px;
  background-color: #101d23;
  color: #137747;
  gap: 10px;
  padding: 0 10px;
  cursor: pointer;
  z-index: 12;
}
.lang-button::after{
  content: '';
  position: absolute;
  border: 6px solid transparent;
  border-top-color: #137747;
  position: absolute;
  top: 50%;
  right: 12px;
  transition: all 0.3s ease;
  transform: translateY(-50%);
  margin-top: 2px;
}
.lang-button:hover:after{
  margin-top: -3px;
  border-top-color:transparent;
  border-bottom-color: #137747;
}


.lang-button img{
  width: 50px;
  border-radius: 6px;
}
.pc-menu-lang{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-radius: 10px;
  padding-top: 5px;
  z-index: 12;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease;
}

.pc-lang:hover .pc-menu-lang{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}




.pc-lang-list{
  padding: 10px;
  border-radius: 10px;
  background-color: #101d23;
}
.pc-lang-list .li-item{
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  background-color: #101d23;
  color: #119450;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 5px;
}
.pc-lang-list .li-item img{
  width: 50px;
  border-radius: 6px;
}
.pc-lang-list .li-item.active{
  background-color: #172b34;
}







@media (max-width: 1024px) {
  .serbia-index .nav-navbar::before{
    height: 48px;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    position: absolute;
    top: 0;
    box-shadow: 0 -10px 30px rgba(97, 220, 129, 0.2);
  }
  .index-m-video{
    display: block;
  }
  .block-conainer{
    max-width: 100%;
  }
  .index-main::before{
    height: 100vh;
    background-size: cover;
  }
  .serbia-index .nav-navbar{
    height: 48px;
    padding-bottom: 0;
    background-image: linear-gradient(90deg, #253036, #66c586 40%, #66c586 40%,rgba(76, 175, 80, .3));
  }
  .index-main{
    padding-top: 100px;
  }
  index-banner{
    height: calc(100vh - 100px);
  }
  .index-footer{
    padding-top: 80px;
    padding-bottom: 80px;
    margin-top: 100px;
  }
  .contact-link{
    margin-bottom: 40px;
  }
  .footer-share .share-link img{
    width: 20px;
  }
  .footer-row{
    display: block;
  }
  .footer-logo{
    margin-bottom: 20px;
  }
  .footer-link{
    margin-bottom: 20px;
    justify-content: space-between;
  }
  .footer-right{
    display: block;
  }
  .footer-right span{
    display: block;
    padding: 10px 0;
    text-align: center;
  }
  .reviews-list{
    padding:0 6px;
  }
  .index-section{
    padding-top: 130px;
    padding-bottom: 50px;
  }
  .section-header{
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .section-header .section-title{
    font-size: 28px;
  }
  .section-header .section-description{
    font-size: 14px;
  }
  .section-ver.ver-left{
    left: 1%;
  }
  .section-ver.ver-right{
    right: 1%;
  }
  .block-main{
    display: block;
  }
  .block-number{
    font-size: 240px;
  }
  .block-item-title{
    font-size: 24px;
  }
  .block-item{
    padding: 0 50px;
    font-size: 15px;
  }
  .block-top{
    font-size: 16px;
    padding:10px 20px;
    line-height: 1.4;
    border-radius: 12px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .block-photo{
    padding-left: 35px;
    margin-left: 15px;
  }
  .block-footer{
    border-radius: 12px;
    font-size: 14px;
    line-height: 1.4;
    padding:15px 20px;
    padding-left: 80px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .block-footer .block-icon{
    width: 55px;
    left: 15px;
  }
  .block-body{
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  .block-panel-items{
    margin-left: auto;
    margin-right: auto;
  }
  .serbia-index .nav-navbar{
    width: auto;
    left: 20px;
    top: 60px;
    right: 20px;
    transform: translateX(0);
  }
  .serbia-index .navbar-nav{
    display: none!important;
  }
  .menu-button{
    display: inline-flex;
  }
  .banner-swiper{
    width: 260px;
    height: 520px;
  }
  .banner-swiper img{
    border-radius: 12px;
  }
  .nav-navbar .nav-container{
    height: 46px;
  }
  .logo-container img{
    width: 36px;
    height: 36px;
  }

  .block-lang{
    display: flex;
    width: 58px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .link-us{
    font-size: 36px;
  }
  .footer-logo{
    display: none;
  }
  .pc-lang{
    display: none;
  }
  .index-m-video{
    padding-top: 0;
  }
}














