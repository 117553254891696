.App {
  text-align: center;
}
//comment
.App-logo {
  height: 40vmin;
  cursor: pointer !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo-wrapper {
  max-width: 500px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.service-link {
  cursor: pointer !important;
  padding: 50px 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;


  @media only screen and (max-width: 600px) {
    padding: 50px 40px;
  }
}
.service-link:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 3px 6px 15px;
  transition: all 420ms cubic-bezier(.165,.84,.44,1);

  .service-title {
    color: #259137;
  }
}

.service-wrapper {
  list-style: inherit;
  padding-left: 0;
  li {
    &::marker {
      color: #259137;
    }
  }
  li:not(:last-child) { margin-bottom: 10px; }
}

//.greeting-wrapper {
//  display: inline-block;
//  margin-bottom: 30px;
//
//  span {
//    color: rgb(117 218 178) !important;
//    font-weight: bold !important;
//  }
//
//  div {
//    display:inline-block;
//    overflow:hidden;
//    white-space:nowrap;
//  }
//
//  div:first-of-type {
//    animation: showup 5s infinite;
//  }
//
//  div:last-of-type {
//    width:0;
//    animation: reveal 5s infinite;
//  }
//
//  div:last-of-type span {
//    display:inline-block;
//    margin-left:-355px;
//    animation: slidein 5s infinite;
//  }
//
//  @keyframes fadeInUp {
//    0% {
//      transform: translateY(100%);
//      opacity: 0;
//    }
//    100% {
//      transform: translateY(0%);
//      opacity: 1;
//    }
//  }
//
//  .fadeInUp-animation {
//    animation: 1.5s fadeInUp;
//  }
//
//  @keyframes showup {
//    0% {opacity:0;}
//    20% {opacity:1;}
//    80% {opacity:1;}
//    100% {opacity:0;}
//  }
//
//  @keyframes slidein {
//    0% { margin-left:-800px; }
//    20% { margin-left:-800px; }
//    35% { margin-left:0; }
//    100% { margin-left:0; }
//  }
//
//  @keyframes reveal {
//    0% {
//      opacity: 0;
//      width: 0;
//    }
//    20% {
//      opacity: 1;
//      width: 0;
//    }
//    30% {
//      width: 355px;
//    }
//    80% {
//      opacity: 1;
//    }
//    100% {
//      opacity: 0;
//      width: 265px;
//    }
//  }
//        }

@media only screen and (max-width: 600px) {
  .greeting-wrapper {
    margin-bottom: 30px;

    span {
      color: #259137 !important;
      font-weight: bold !important;
    }
  }
}

@media only screen and (min-width: 600px) {
  .greeting-wrapper {
    display: inline-block;

    span {
      color: #259137 !important;
      font-weight: bold !important;
    }

    div {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }

    div:first-of-type {
      animation: showup 5s infinite;
    }

    div:last-of-type {
      width: 0;
      animation: reveal 5s infinite;
    }

    div:last-of-type span {
      display: inline-block;
      margin-left: -355px;
      animation: slidein 5s infinite;
    }

    @keyframes fadeInUp {
      0% {
        transform: translateY(100%);
        opacity: 0;
      }
      100% {
        transform: translateY(0%);
        opacity: 1;
      }
    }

    .fadeInUp-animation {
      animation: 1.5s fadeInUp;
    }

    @keyframes showup {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 1;
      }
      80% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes slidein {
      0% {
        margin-left: -800px;
      }
      20% {
        margin-left: -800px;
      }
      35% {
        margin-left: 0;
      }
      100% {
        margin-left: 0;
      }
    }

    @keyframes reveal {
      0% {
        opacity: 0;
        width: 0;
      }
      20% {
        opacity: 1;
        width: 0;
      }
      30% {
        width: 355px;
      }
      80% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        width: 265px;
      }
    }
  }
}

.navbar-brand1 {
  max-width: 270px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.footer {
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 1) 23%, hsla(130, 59%, 36%, 1) 100%)
}

.footer-link {
  color: black !important;
}

.client-image { object-fit: cover; }

.about-company-wrapper {
  max-width: 900px;
  margin: 50px auto;
  padding: 0 15px;
}

.reserved {
  text-align: center;
  padding-bottom: 60px;
  color: black;
}

.absolute-wrapper-container {
  background: #f8fafc;
}

.absolute-wrapper {
  background: linear-gradient(180deg, hsla(210, 40%, 98%, 1) 23%, hsla(130, 59%, 36%, 1) 100%)
}