.home-faq-wrapper {
  position: relative;
  z-index: 30;
  max-width: 1160px;
  padding: 0 20px;
  margin: 0 auto;

  .home-faq-title {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-bottom: 50px;
    text-align: center;

    @media only screen and (max-width: 799px) {
      font-size: 28px;
    }

    @media only screen and (max-width: 529px) {
      font-size: 24px;
      margin-bottom: 20px;
      padding-left: 16px;
    }
  }

  .home-faq-inner {
    display: flex;
    align-items: flex-start;

    @media (max-width: 899px) {
      flex-direction: column;
    }

    .home-faq-navigation-wrapper {
      background-color: rgb(249, 250, 251) !important;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 0.5rem;
      padding: 16px;
      margin-right: 40px;
      width: 302px;
      flex-shrink: 0;
      scroll-behavior: smooth;

      @media (max-width: 899px) {
        min-width: 100%;
        overflow-x: auto;
        display: flex;
        align-items: flex-start;
        margin-right: 0;
        margin-bottom: 32px;
        scrollbar-width: none;
        -ms-overflow-style: none;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        background-color: rgb(249, 250, 251) !important;
        border-radius: 0;
        z-index: 1;
      }

      @media (max-width: 529px) {
        padding-right: 0;
        padding-left: 0;
      }

      .home-faq-navigation-item {
        border-radius: 10px;
        padding: 16px;
        transition: all 160ms ease-in-out;
        cursor: pointer;
        color: #999999;
        font-size: 16px;

        &:hover {
          color: #259137;
        }

        &.active {
          color: #259137;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

        @media (min-width: 900px) {
          &:not(:last-child) {
            margin-bottom: 32px;
          }
        }

        @media (max-width: 899px) {
          white-space: nowrap;
          padding: 7px 0;
          margin: 0 16px 0 16px;
          border-bottom: 2px solid transparent;
          border-radius: 0;

          &.active {
            color: #ADFF00;
            border-bottom-color: #ADFF00;
            background-color: transparent;
          }
        }
      }
    }

    .home-faq-content-wrapper {
      opacity: 0;
      max-width: 100%;
      width: 100%;

      &.animate > div {
        margin-bottom: 100px;
      }

      &.animate1 {
        opacity: 1;
        transition: all 320ms ease-in-out;
      }

      & > div {
        margin-bottom: 32px;
        transition: all 400ms ease-in-out;

        @media (max-width: 599px) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
