.languages {
  margin-left: 50px;
}

@media only screen and (max-width: 850px) {
  .languages {
    margin-left: 0;
    margin-top: 20px;
  }
}

.flag {
  transition: all 420ms cubic-bezier(.165,.84,.44,1);
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.flag:not(:last-child) {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #75dab2;
  border-radius: 1px;
}